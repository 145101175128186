import { useEffect, useState } from "react";
import "./Cupos.css";
import { useAppDispatch } from "../../app/store";
import {
  selectCuadraturaCupos,
  selectCuposAnalizados,
  selectCuposTotales,
  selectDetalleCupo,
} from "../../features/Dashboard/Slices/dashboardSlice";
import Input from "../../components/reusable/Input/Input";
import Button from "../../components/reusable/Button/Button";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import { useSelector } from "react-redux";
import TableList from "../../components/TableList/TableList";
import TableListInverted from "../../components/TableListInverted/TableListInverted";
import { dashboardAPI } from "../../Api/dashboardAPI";
import { toastInfo, toastSuccess } from "../../utils/toast";
import { getDetalleDeCupo } from "../../features/Dashboard/AsyncThunks/cupos/getDetalleDeCupo";
import {
  getDetalleCuadratura,
  procesarCuadratura,
} from "../../features/Dashboard/AsyncThunks/cupos/getDetalleCuadratura";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";


function Cupos() {
  const cuposTotales = useSelector(selectCuposTotales);
  const cuposAnalizados = useSelector(selectCuposAnalizados);
  const [loading, setLoading] = useState(false);
  const [idCupo, setIdCupo] = useState<number | null>(null);
  const detalleCupo = useSelector(selectDetalleCupo);
  const cuadraturaCupo = useSelector(selectCuadraturaCupos);
  const dispatch = useAppDispatch();

  const updateCupo = async () => {
    if (idCupo) {
      setLoading(true);
      await dashboardAPI.actualizarCupo(idCupo);
      //await dispatch(getDetalleDeCupo(idCupo));
      toastSuccess("En unos instantes se actualizará la información");
      setLoading(false);
    } else {
      toastInfo("Ingrese idCupo");
    }
  };

  const syncCuposDescuadrados = async () => {
    setLoading(true);
    await dashboardAPI.syncCuposDescuadrados();
    toastSuccess("En unos instantes se actualizará la información");
    setLoading(false);
  };

  const getCuadratura = async () => {
    setLoading(true);
    await dispatch(getDetalleCuadratura());
    setLoading(false);
  };

  const calcularCuadratura = async () => {
    setLoading(true);
    await dispatch(procesarCuadratura());
    setLoading(false);
  };

  const getDetalleCupo = async () => {
    if (idCupo) {
      setLoading(true);
      await dispatch(getDetalleDeCupo(idCupo));
      setLoading(false);
    } else {
      toastInfo("Ingrese idCupo");
    }
  };
  useEffect(() => {
    console.log("detalleCupo :>> ", detalleCupo);
  }, [detalleCupo]);

  return (
    <div className="flex-1 h-full flex gap-4 p-4">
      <div className="w-[1200px]">
        {loading && <PageLoader />}
        <div className="flex flex-row mb-2">
          <Input
            onChange={(text) => setIdCupo(Number(text))}
            type="number"
            placeholder="Ingrese idCupo (número)"
            className="w-3/12"
          />
          <button
            className="w-32 text-white bg-primary rounded-r-md"
            onClick={getDetalleCupo}
          >
            Buscar
          </button>
        </div>
        {detalleCupo && (
          <div className="py-4">
            <h4 className="uppercase font-semibold text-primary border-b-[1px] border-primary mb-4">
              Detalle cupo
            </h4>
            <div className="grid grid-cols-3 gap-2">
              {Object.keys(detalleCupo).map((key: string) => {
                return (
                  <div
                    className={`bg-[white] p-1 px-2 rounded-md flex gap-2 ${
                      key === "detalle" ? "col-span-2" : ""
                    }`}
                  >
                    <p className="flex-grow">{key}</p>
                    <p className="min-w-[20%] text-right px-4 rounded-sm font-semibold">
                      {/* @ts-ignore */}
                      {detalleCupo[key]}
                    </p>
                  </div>
                );
              })}
            </div>
            {/* <TableListInverted list={[detalleCupo]} /> */}
          </div>
        )}
        <h4 className="uppercase font-semibold text-primary border-b-[1px] border-primary mb-4">
          Cuadratura de cupos
        </h4>
        <div className="bg-white rounded-md p-4">
          <div className="flex">
            <h4 className="">Cuadratura de cupos</h4>
            <p className="flex-grow text-center font-semibold">
              Total: {cuposTotales} / Analizados: {cuposAnalizados}
            </p>
          </div>
          <hr className="text-secondary my-2" />
          {cuadraturaCupo.length > 0 ? (
            <TableList list={cuadraturaCupo} />
          ) : (
            <p className="text-secondary">
              No se encuentran datos de cuatratura
            </p>
          )}
        </div>
      </div>
      <div className="flex-grow">
        <div className="sticky top-6 flex flex-col gap-4">
          <SlButton
            variant="primary"
            outline
            className="uppercase"
            onClick={updateCupo}
          >
            <SlIcon slot="prefix" name="arrow-clockwise"></SlIcon>
            Actualizar desde BO
          </SlButton>
          <SlButton variant="primary" outline onClick={getCuadratura}>
            <SlIcon slot="prefix" name="cloud-arrow-down"></SlIcon>
            Consultar Cuadratura
          </SlButton>
          <SlButton variant="primary" outline onClick={calcularCuadratura}>
            <SlIcon slot="prefix" name="calculator"></SlIcon>
            Calcular Cuadratura
          </SlButton>
          <SlButton variant="primary" outline onClick={syncCuposDescuadrados}>
            <SlIcon slot="prefix" name="arrow-repeat"></SlIcon>
            Sincronizar Cupos Descuadrados
          </SlButton>
        </div>
      </div>
    </div>
  );
}

export default Cupos;

import { useEffect, useState } from "react";
import { getProjectVersion } from "../../services/gitlabApi";
import Bullets from "./Bullets";
import { GitMerge } from "lucide-react";
import { MergeRequest } from "../../services/types/gitlab";

export function versionIsUpdated(version: string, lastVersion: string) {
  const actual = Number(version.replace(".", ""));
  const last = Number(lastVersion.replace(".", ""));
  return actual === last;
}

interface Props {
  projectId: number;
  branchName: string;
  lastVersion: string;
  addReadyBranch: (param: string) => void;
  merges: MergeRequest[];
}
export default function RepoBranchDetail({
  projectId,
  branchName,
  lastVersion,
  addReadyBranch,
  merges,
}: Props) {
  const [version, setVersion] = useState("");
  const [loading, setLoading] = useState(true);
  const [updatedVersion, setUpdatedVersion] = useState(false);
  const [branchError, setBranchError] = useState(false);

  useEffect(() => {
    async function fetcher() {
      const res = await getProjectVersion(projectId, branchName);
      if (!res.version) {
        console.log("errorrrrrrrrrrrr");
        setBranchError(true);
        addReadyBranch(branchName);
        setUpdatedVersion(true)
        setLoading(false);
      }else{
        setVersion(res.version);
        setLoading(false);
      }

    }
    try {
      fetcher();
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (version !== "") {
      if(!branchError){
        setUpdatedVersion(versionIsUpdated(version, lastVersion));
      }
      setLoading(false);
      addReadyBranch(branchName);
    }
  }, [version]);

  if (loading && version === "") return <div>⏰</div>;


  return (
    <div
      className={`w-[150px] ${
        branchName === "main" ? "text-white" : ""
      }`}
    >
      <div className="w-[150px] text-right pr-[15px]">
        <p className="text-sm font-semibold">{branchError ? "n/a": branchName}</p>
        <p className={branchError ? "invisible":""}>{branchError ? "." :("v "+version)}</p>
      </div>
      <div className="flex">
        <Bullets
          isFirst={"develop" === branchName}
          isLastVersion={updatedVersion}
          noStop={branchError}
        />
      </div>
      {merges.length > 0 &&
        merges.map((mr) => (
          <div key={mr.id} className="flex items center text-xs px-4">
            <GitMerge />
            <p className="truncate">{mr.title}</p>
          </div>
        ))}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import JsonViewer from "../../../components/reusable/JsonViewer/JsonViewer";
import TableList from "../../../components/TableList/TableList";
import { selectDetalleDeAlumno, setDetalleAlumnoEmpty } from "../../../features/Dashboard/Slices/alumnosSlice";
import SlDialog from "@shoelace-style/shoelace/dist/react/dialog";
import { useAppDispatch } from "../../../app/store";

function DetalleAlumno() {
  const dispatch = useAppDispatch();
  const detalleAlumno = useSelector(selectDetalleDeAlumno);
  const [isOpen, setIsOpen] = useState(false)
  const [reservasEnTransito, setReservasEnTransito] = useState<any[] | null>(
    null
  );
  const [desinscripcionesEnTransito, setDesinscripcionesEnTransito] = useState<
    any[] | null
  >(null);

  useEffect(() => {
    if (detalleAlumno) {
      if (detalleAlumno?.inscripcionesEnTransito) {
        setReservasEnTransito(detalleAlumno.inscripcionesEnTransito);
      }
      if (detalleAlumno?.desinscripcionesEnTransito) {
        setDesinscripcionesEnTransito(detalleAlumno.desinscripcionesEnTransito);
      }
    }
  }, [detalleAlumno]);

  useEffect(() => {
    return () => {      
      dispatch(setDetalleAlumnoEmpty());
    }
  }, [])
  

  if(!detalleAlumno){
    return null
  }

  return (
    <SlDialog
      label={`Detalle`}
      // : ${detalleAlumno.alumno.nombre} [${detalleAlumno.alumno.nombre_carrera}]
      open={detalleAlumno}
      // @ts-ignore
      style={{ "--width": "75vw" }}
      onSlAfterHide={() => setIsOpen(false)}
    >
      {detalleAlumno.alumno &&
      <p className="text-center">{detalleAlumno.alumno.nombre}</p>}
      {reservasEnTransito && (
        <div>
          <div className="flex flex-row items-center pb-3">
            <b className="text-left w-2/12">Reservas en transito</b>
          </div>
          <TableList list={reservasEnTransito} />
          {reservasEnTransito?.length < 1 && (
            <p>No se encontraron reservas en transito</p>
          )}
        </div>
      )}
      {desinscripcionesEnTransito && (
        <div>
          <div className="flex flex-row items-center pt-3 pb-3">
            <b className="text-left w-2/12">Desinscripciones en transito</b>
          </div>
          <TableList list={desinscripcionesEnTransito} />
          {desinscripcionesEnTransito?.length < 1 && (
            <p>No se encontraron desinscripciones en transito</p>
          )}
        </div>
      )}
      <b className="text-center">Detalle del alumno</b>
      {/* <div className="flex flex-row justify-center mt-2">
          <Input
            className="w-10/12"
            placeholder="Ingrese el IdAlumnoProgramar(Número)"
            type="number"
            onChange={(text) => setIdAlumnoPrograma(Number(text))}
          />
          <Button
            className="ml-3 w-2/12"
            onClick={ObtenerDetalleAlumno}
            label="Buscar"
          />
        </div> */}
      <div className="pt-5 w-12/12">
        <JsonViewer json={detalleAlumno} />
      </div>
    </SlDialog>
  );
}

export default DetalleAlumno;

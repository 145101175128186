import { useEffect, useState } from "react";
import TableList from "../../components/TableList/TableList";
import Pagination from "../../components/reusable/Pagination/Pagination";
import { dashboardAPI } from "../../Api/dashboardAPI";
import { toastError } from "../../utils/toast";
import "./Transacciones.css";
import Button from "../../components/reusable/Button/Button";
import { useAppDispatch } from "../../app/store";
import { setLoadingPageScreen } from "../../features/Dashboard/Slices/dashboardSlice";
import xlsx from "json-as-xlsx";
import { DateInput } from "../../components/DateInput";
import { TimeInput } from "../../components/TimerInput";
import ModalConfirmation from "../../components/reusable/ModalConfirmation/ModalConfirmation";
import dateFormat from "dateformat";
import Input from "../../components/reusable/Input/Input";
import Selector from "../../components/reusable/Selector/Selector";
export enum EstadoTX {
  DESCONOCIDO = "DESCONOCIDO",
  INICIADA = "INICIADA",
  "RECHAZADA CLOUD SYNC" = "RECHAZADA_CLOUD_SYNC",
  "RECHAZADA CLOUD ASYNC" = "RECHAZADA_CLOUD_ASYNC",
  "RECHAZADA ORACLE" = "RECHAZADA_ORACLE",
  "APROBADA CLOUD" = "APROBADA_CLOUD",
  "APROBADA ORACLE" = "APROBADA_ORACLE",
}

function Transacciones() {
  const [fecha, setFecha] = useState(dateFormat(new Date(), "yyyymmdd"));
  const [hora, setHora] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [estado, setEstado] = useState("");
  const [idTx, setIdTx] = useState("");
  const [totalEvents, setTotalEventos] = useState(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [eventos, setEventos] = useState<any[]>([]);
  const [desinscripcionesTransito, setDesinscripcionesTransito] = useState(0);
  const [reservasEnTransito, setReservasEnTransito] = useState(0);
  const [txIniciadas, setTxIniciadas] = useState(0);
  const [txAprobadasCloud, setTxAprobadasCloud] = useState(0);
  const [txRechazasCloud, setTxRechazasCloud] = useState(0);
  const [txRechazasOracle, setTxRechazasOracle] = useState(0);
  const [txAprobadasOracle, setTxAprobadasOracle] = useState(0);

  const estadosOptions = [
    { text: "DESCONOCIDO", value: "DESCONOCIDO" },
    { text: "INICIADA", value: "INICIADA" },
    { text: "RECHAZADA_CLOUD_SYNC", value: "RECHAZADA_CLOUD_SYNC" },
    { text: "RECHAZADA_CLOUD_ASYNC", value: "RECHAZADA_CLOUD_ASYNC" },
    { text: "RECHAZADA_ORACLE", value: "RECHAZADA_ORACLE" },
    { text: "APROBADA_CLOUD", value: "APROBADA_CLOUD" },
    { text: "APROBADA_ORACLE", value: "APROBADA_ORACLE" },
  ];

  const pageSize = 100;
  const dispatch = useAppDispatch();

  const setLoading = (newState: boolean) => {
    dispatch(setLoadingPageScreen(newState));
  };

  const exportTransacciones = async () => {
    try {
      const fechaNum = parseInt(fecha);
      const horaNum = hora ? parseInt(hora) : null;
      const data = await dashboardAPI.exportTransacciones(fechaNum, horaNum);
      let settings = {
        fileName: "Transacciones", // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
        RTL: false, // Display the columns from right-to-left (the default value is false)
      };

      xlsx(data, settings);
    } catch (error) {
      console.log("ERROR: " + JSON.stringify(error));
      toastError();
    }
  };

  const delTransacciones = async () => {
    await dashboardAPI.delTransacciones();
    setLoading(true);
    try {
      getTransacciones();
    } catch (error) {
      toastError();
    }
    setShowDeleteConfirmation(false);
    setLoading(false);
  };

  const getTransacciones = async (newPage?: number) => {
    setLoading(true);
    try {
      const page = newPage ? (newPage - 1) * pageSize : 0;

      const newData = await dashboardAPI.getTransaccionesPaginated(
        pageSize,
        page,
        Number.parseInt(fecha),
        Number.parseInt(hora),
        estado.length > 0 ? EstadoTX[estado as keyof typeof EstadoTX] : null,
        idTx.length > 0 ? Number(idTx) : null
      );
      if (newPage) {
        setCurrentPage(newPage);
      } else {
        setCurrentPage(1);
      }
      const estados = await dashboardAPI.getEstadoGlobalTransacciones();

      setDesinscripcionesTransito(estados.DesinscripcionesTransito);
      setReservasEnTransito(estados.ReservasEnTransito);
      setTxIniciadas(estados.TxIniciadas);
      setTxAprobadasCloud(estados.TxAprobadasCloud);
      setTxRechazasCloud(estados.TxRechazasCloud);
      setTxRechazasOracle(estados.TxRechazasOracle);
      setTxAprobadasOracle(estados.TxAprobadasOracle);

      setEventos(newData.transacciones);
      setTotalEventos(newData.totalCount);
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };

  useEffect(() => {
    getTransacciones();
  }, []);

  return (
    <div className="flex flex-1 transaccionesPage">
      <ModalConfirmation
        topBorderWarn
        show={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={() => delTransacciones()}
        title="¿Desea eliminar todos las transacciones registradas?"
      />
      <div className="transaccionesPage">
        <div className="pt-3 flex flex-row items-center">
          <strong className="pl-10 text-lg">Transacciones</strong>
          {/* Agregar los nuevos inputs */}
          <div className="ml-3 flex gap-4">
            <DateInput
              value={fecha}
              onChange={setFecha}
              label="Fecha"
              required
            />
            <TimeInput value={hora} onChange={setHora} label="Hora" />
            <div className="flex flex-col">
              <strong className="text-sm">Estado</strong>
              <Selector
                options={estadosOptions}
                onSelect={setEstado}
                placeholder="Estado"
              />
            </div>
            <div className="flex flex-col w-40">
              <strong className="text-sm">idTx</strong>
              <Input
                defaultValue={idTx}
                onChange={setIdTx}
                placeholder="idTx"
                type="number"
              />
            </div>
          </div>
          <Button
            className="ml-3"
            label="Actualizar lista"
            onClick={() => getTransacciones()}
          />
          <Button
            className="ml-3"
            label="Borrar todo"
            onClick={() => setShowDeleteConfirmation(true)}
            theme="red"
          />
          <Button
            className="ml-3"
            label="Exportar a XLS"
            onClick={() => exportTransacciones()}
            theme="green"
          />
        </div>
        <div className="pt-3 flex flex-row items-center">
          <div className="flex flex-col px-10 py-2 status">
            ReservasEnTransito: {reservasEnTransito} - DesinscripcionesTransito:{" "}
            {desinscripcionesTransito} - TxIniciadas: {txIniciadas} -
            TxAprobadasCloud: {txAprobadasCloud} - TxRechazasCloud:{" "}
            {txRechazasCloud} - TxRechazasOracle: {txRechazasOracle} -
            TxAprobadasOracle: {txAprobadasOracle}
          </div>
        </div>
        <div className="flex flex-col px-10 py-2">
          {eventos.length < 1 && <h2>No se encontraron transacciones</h2>}
          <div className="tableListCont">
            <TableList list={eventos} />
          </div>
          <Pagination
            onPageChange={getTransacciones}
            currentPage={currentPage}
            totalCount={totalEvents}
            pageSize={pageSize}
          />
        </div>
      </div>
    </div>
  );
}

export default Transacciones;

import React, { useEffect, useState } from "react";
import SlOption from "@shoelace-style/shoelace/dist/react/option";
import SlSelect from "@shoelace-style/shoelace/dist/react/select";
interface Props {
  options: { value: string; text: string }[];
  placeholder?: string;
  onSelect: (value: string) => void;
  className?: string;
  label?: string;
}

function Selector({ options, placeholder, onSelect, className, label }: Props) {
  const handleValueChange = (text: string) => {
    const textParsed = text.replaceAll("_", " ");
    onSelect(textParsed);
  };

  return (
    <div className={className}>
      <p>{label}</p>
      <SlSelect
        placeholder={placeholder}
        onSlChange={(e: any) => handleValueChange(e?.target?.value)}
      >
        <SlOption value={""}>Sin filtrar carrera </SlOption>
        {options.map(({ text, value }) => (
          <SlOption value={value}>{text}</SlOption>
        ))}
      </SlSelect>
    </div>
  );
}

export default Selector;

interface BulletProps {
  isFirst: boolean;
  isLastVersion: boolean;
  noStop: boolean;
}
export default function Bullets({
  isFirst,
  isLastVersion,
  noStop,
}: BulletProps) {
  if (noStop) {
    return (
      <div className="flex items-center justify-end w-[200px] h-[20px]">
        <div
          className={`w-[200px] h-[5px] ${
            isLastVersion ? "bg-[#10b981]" : "bg-[#cbd5e1]"
          }`}
        ></div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-end w-[200px]">
      {!isFirst && (
        <div
          className={`w-[130px] h-[5px] ${
            isLastVersion ? "bg-[#10b981]" : "bg-[#cbd5e1]"
          }`}
        ></div>
      )}
      <div
        className={`w-[20px] h-[20px] border-[5px] bg-white rounded-full ${
          isLastVersion ? "border-[#10b981]" : "border-[#cbd5e1]"
        }`}
      ></div>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

interface GlobalFlags {
  drawerInicializar: boolean;
  alumnoDetailLoading: number | null
}
const initState: GlobalFlags = {
  drawerInicializar: false,
  alumnoDetailLoading: null
};

const globalFlagsSlice = createSlice({
  name: "globalFlags",
  initialState: initState,
  reducers: {
    setDrawerInicializar: (state, { payload }) => {
      state.drawerInicializar = payload;
    },
    setAlumnoDetailLoading: (state, { payload }) => {
      state.alumnoDetailLoading = payload;
    },
  },
});

export const selectGlobalFlags = (state: RootState) => state.globalFlags;

export const { setDrawerInicializar, setAlumnoDetailLoading } = globalFlagsSlice.actions;

export default globalFlagsSlice.reducer;

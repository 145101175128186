import React, { useEffect } from "react";
// App.jsx
import "@shoelace-style/shoelace/dist/themes/light.css";
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path";
import "./App.css";
import "./variables.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import Sidebar from "./components/Sidebar/Sidebar";
import Searchbar from "./components/Searchbar/Searchbar";
import EstadoGeneral from "./pages/EstadoGeneral/EstadoGeneral";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Cupos from "./pages/Cupos/Cupos";
import Parametros from "./pages/Parametros/Parametros";
import Alumnos from "./pages/Alumnos/Alumnos";
import Validaciones from "./pages/Validaciones/Validaciones";
import Colas from "./pages/Colas/Colas";
import Servicios from "./pages/Servicios/Servicios";
import Novedades from "./pages/Novedades/Novedades";
import Reservas from "./pages/Reservas/Reservas";
import Eventos from "./pages/Eventos/Eventos";
import { useSelector } from "react-redux";
import {
  selectAuthenticated,
  setToken,
  setUser,
} from "./features/Dashboard/Slices/userSlice";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import AxiosManager from "./components/AxiosManager";
import { useAppDispatch } from "./app/store";
import { jwtDecode } from "jwt-decode";
import PageLoader from "./components/reusable/PageLoader/PageLoader";
import { selectLoaderPage } from "./features/Dashboard/Slices/dashboardSlice";
import Transacciones from "./pages/Transacciones/Transacciones";

setBasePath(
  "https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.15.0/cdn/"
);

interface tknPayload {
  role: string;
  email: string;
}

function App() {
  const authenticated = useSelector(selectAuthenticated);
  const pageLoader = useSelector(selectLoaderPage);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const tkn = localStorage.getItem("token");
    if (tkn) {
      dispatch(setToken(tkn));
    }

    const decodePayload = async () => {
      if (!tkn) {
        return;
      }
      const decodedInfo: tknPayload = await jwtDecode(tkn);

      if (!decodedInfo.role) {
        return;
      }
      const user = {
        rol: decodedInfo.role,
        usuario: decodedInfo.email,
        acciones: [],
      };

      dispatch(setUser(user));
    };
    decodePayload();
  }, []);

  return (
    <BrowserRouter>
      <div className="flex flex-row flex-1 h-full">
        <ToastContainer />
        {authenticated && <Sidebar />}
        <div
          className={authenticated ? "pageContent bg-grey" : "main-container"}
        >
          {pageLoader && <PageLoader />}
          {authenticated && <Searchbar />}
          <AxiosManager />
          <div>
            <Routes>
              <Route Component={Login} path="/login" />
              <Route
                element={<ProtectedRoute isAuthenticated={authenticated} />}
              >
                <Route Component={Home} path="/" />
                <Route Component={EstadoGeneral} path="/estado_general" />
                <Route Component={Cupos} path="/cupos" />
                <Route Component={Colas} path="/colas" />
                <Route Component={Parametros} path="/parametros" />
                <Route Component={Alumnos} path="/alumnos" />
                <Route Component={Validaciones} path="/validaciones" />
                <Route Component={Servicios} path="/services" />
                <Route Component={Novedades} path="/novedades" />
                <Route Component={Reservas} path="/reservas" />
                <Route Component={Eventos} path="/eventos" />
                <Route Component={Transacciones} path="/transacciones" />
              </Route>
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;

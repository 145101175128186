import { conf } from "../utils/config";
import { GitProject } from "./types/gitlab";

const baseURLGitlabApi = conf('GITLAB_API_URL');
const gitLabToken = conf('GITLAB_TOKEN');

async function fetchFromGitLab(endpoint: string) {
  try {
    const res = await fetch(`${baseURLGitlabApi}/${endpoint}`, {
      headers: {
        "PRIVATE-TOKEN": `${gitLabToken}`,
      },
    });
    if (!res.ok) {
      throw new Error(`Error al consultar ${endpoint}: ${res.status}`);
    }

    return res.json();
  } catch (error) {
    console.log("ERROR =>", error);
    return error;
  }
}

// Obtener proyectos (por ejemplo los del usuario actual)
export function getUserProjects() {
  return fetchFromGitLab(`projects?membership=true`);
}

// Obtener tags (versiones) de un proyecto
export function getProjectTags(projectId: number) {
  return fetchFromGitLab(`projects/${projectId}/repository/tags`);
}

// Obtener ramas
export function getProjectBranches(projectId: number) {
  return fetchFromGitLab(`projects/${projectId}/repository/branches`);
}

// Obtener información general del proyecto
export function getProjectInfo(projectId: number): Promise<GitProject> {
  return fetchFromGitLab(`projects/${projectId}`);
}

// Obtener version del proyecto
export function getProjectVersion(projectId: number, branch: string) {
  try {
    return fetchFromGitLab(
      `projects/${projectId}/repository/files/package.json/raw?ref=${branch}`
    );
  } catch (error: any) {
    return { error: true };
  }
}
// Obtener MRs
export function getProjectMergeRequests(projectId: number) {
  try {
    return fetchFromGitLab(`projects/${projectId}/merge_requests?state=opened`);
  } catch (error: any) {
    return { error: true };
  }
}

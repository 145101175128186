import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardAPI } from "../../../../Api/dashboardAPI";
import { toastError, ToastMessage } from "../../../../utils/toast";
import { AlumnosInitState } from "../../Slices/alumnosSlice";

export const getCarreras = createAsyncThunk(
  "dashboard/getCarreras",
  async (_, thunkAPI) => {
    try {
      console.log("getCarreras...");
      const response = await dashboardAPI.getCarreras();
      console.log("response :>> ", response);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getCarrerasBuilder = (
  builder: ActionReducerMapBuilder<AlumnosInitState>
) => {
  builder.addCase(getCarreras.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getCarreras.fulfilled, (state, action) => {
    console.log("getCarreras fulfilled :>> ", action);
    state.loading = false;
    state.carreras = action.payload || [];
  });
  builder.addCase(getCarreras.rejected, (state, action) => {
    console.log("inicializarAlumno rejected :>> ", action);
    // toastError(ToastMessage.error);
    state.loading = false;
  });
};

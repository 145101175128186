import { useState } from "react";
import { copyTextToClipboard } from "../../utils";

interface IPropsLayout {
  children: React.ReactNode;
  textToCopy:string
}

export default function CopyTooltip({ children, textToCopy }: IPropsLayout) {
  const [text, setText] = useState("Click para copiar")

  const handleClick = ()=>{
    copyTextToClipboard(textToCopy)
    setText("Copiado 📋")
    setTimeout(() => {
      setText("Click para copiar")
    }, 1000);
  }

  return (
    <div onClick={handleClick} className="relative inline group">
      <div
        className="bg-black absolute top-[-25px] left-[10px] text-white px-2 py-1 invisible group-hover:visible"
        style={{ borderRadius: "5px 5px 5px 0px" }}
      >
        <p className="text-nowrap">
        {text}
        </p>
      </div>
      {children}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectServicesVersions } from "../../features/Dashboard/Slices/dashboardSlice";
import { useAppDispatch } from "../../app/store";
import TableList from "../../components/TableList/TableList";
import Button from "../../components/reusable/Button/Button";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import { getServicesVersions } from "../../features/Dashboard/AsyncThunks/servicios/getServicesStatus";
import RepoTimeline from "../../components/GitLabTimeline/RepoTimeline";

function Servicios() {
  const servicesVersions = useSelector(selectServicesVersions);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const getServicesData = async () => {
    setLoading(true);
    await dispatch(getServicesVersions());
    setLoading(false);
  };

  useEffect(() => {
    getServicesData();
  }, []);

  return (
    <div className="flex items-start gap-4">
      {loading && <PageLoader />}

      <div className="p-5">
        <div className="flex flex-row items-center">
          <strong className="px-2 my-4 text-base">
            Versiones de servicios
          </strong>
          <Button
            className="h-9"
            label="Actualizar"
            iconName="arrow-clockwise"
            onClick={getServicesData}
          />
        </div>
        <TableList list={servicesVersions} />
      </div>

      <div className="min-w-[920px] relative overflow-hidden">
        <div className="z-0 absolute flex h-screen font-bold">
          <div className="border-2 w-[300px] invisible"></div>
          <div className="border-l-2 border-[#ffffff] w-[150px] h-full bg-[#eef2ff]">
            <h2 className="text-center pt-4">DEVELOP</h2>
          </div>
          <div className="border-l-2 border-[#ffffff] w-[150px] h-full bg-[#e0e7ff]">
            <h2 className="text-center pt-4">TESTING</h2>
          </div>
          <div className="border-l-2 border-[#ffffff] w-[150px] h-full ">
            <h2 className="text-center pt-4">STAGING</h2>
          </div>
          <div className="border-l-2 border-[#ffffff] w-[150px] h-full bg-[#6366f1]">
            <h2 className="text-center pt-4 text-white">PRODUCTION</h2>
          </div>
        </div>

        <div className="z-10 mt-10 relative space-y-6 py-4">
          {/* Frontend */}
          <RepoTimeline projectId={406} />
          {/* Dashboard */}
          <RepoTimeline projectId={419} />
          {/* Notifications */}
          <RepoTimeline projectId={411} />
          {/* Api */}
          <RepoTimeline projectId={407} />
          {/* Sync */}
          <RepoTimeline projectId={410} />
          {/* Proxy */}
          <RepoTimeline projectId={404} />
        </div>
      </div>
    </div>
  );
}

export default Servicios;

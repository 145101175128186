import React, { useEffect, useState } from "react";
import TableList from "../../components/TableList/TableList";
import Pagination, {
  defaultPaginationConfig,
} from "../../components/reusable/Pagination/Pagination";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import { dashboardAPI } from "../../Api/dashboardAPI";
import { toastError } from "../../utils/toast";
import "./Eventos.css";
import Button from "../../components/reusable/Button/Button";
import { useAppDispatch } from "../../app/store";
import { setLoadingPageScreen } from "../../features/Dashboard/Slices/dashboardSlice";
import ModalConfirmation from "../../components/reusable/ModalConfirmation/ModalConfirmation";
import xlsx from "json-as-xlsx";
import Input from "../../components/reusable/Input/Input";
import Selector from "../../components/reusable/Selector/Selector";
const tipoEventosOptions = [
  { text: "DASHBOARD", value: "DASHBOARD" },
  { text: "CRON", value: "CRON" },
  { text: "ALUMNO", value: "ALUMNO" },
  { text: "NOVEDAD", value: "NOVEDAD" },
  { text: "ERROR", value: "ERROR" },
  { text: "CUPO", value: "CUPO" },
  { text: "INSCRIPCION", value: "INSCRIPCION" },
  { text: "GENERAL", value: "GENERAL" },
  { text: "PROXY", value: "PROXY" },
];

function Eventos() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEvents, setTotalEventos] = useState(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [eventos, setEventos] = useState<any[]>([]);
  const [tipoEvento, setTipoEvento] = useState("");
  const [idTx, setIdTx] = useState("");
  const [componente, setComponente] = useState("");
  const [idCupo, setIdCupo] = useState<number | null>(null);
  const [idAlumnoPrograma, setIdAlumnoPrograma] = useState<number | null>(null);
  const pageSize = 100;
  const dispatch = useAppDispatch();

  const setLoading = (newState: boolean) => {
    dispatch(setLoadingPageScreen(newState));
  };

  const delEventos = async () => {
    await dashboardAPI.delEventos();
    getEventos();
    setShowDeleteConfirmation(false);
  };

  const exportEventos = async () => {
    try {
      const data = await dashboardAPI.exportEventos();
      let settings = {
        fileName: "Eventos", // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
        RTL: false, // Display the columns from right-to-left (the default value is false)
      };
      console.log("data:", data);
      xlsx(data, settings);
    } catch (error: any) {
      console.log(`ERROR: ${error.message}`);
      toastError();
    }
  };

  const getEventos = async (newPage?: number) => {
    setLoading(true);
    try {
      const page = newPage ? (newPage - 1) * pageSize : 0;
      console.log("newPage;", newPage);
      console.log("page:", page);
      const newData = await dashboardAPI.getEventosPaginated(
        pageSize,
        page,
        tipoEvento.length > 0 ? tipoEvento : null,
        idTx.length > 0 ? Number(idTx) : null,
        componente.length > 0 ? componente : null,
        idCupo && idCupo > 0 ? idCupo : null,
        idAlumnoPrograma && idAlumnoPrograma > 0 ? idAlumnoPrograma : null
      );
      if (newPage) {
        setCurrentPage(newPage);
      } else {
        setCurrentPage(1);
      }
      setEventos(newData.eventos);
      setTotalEventos(newData.totalCount);
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };

  useEffect(() => {
    getEventos();
  }, []);

  return (
    <div className="flex flex-1">
      <ModalConfirmation
        topBorderWarn
        show={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={() => delEventos()}
        title="¿Desea eliminar todos los eventos registrados?"
      />
      <div className="eventosPage">
        <div className="pt-3 pl-10 flex flex-row items-center">
          <div className="flex flex-col w-40">
            <strong>Tipo de evento</strong>
            <Selector
              placeholder="Tipo de evento"
              options={tipoEventosOptions}
              className="w-full"
              onSelect={(value) => setTipoEvento(value)}
            />
          </div>
          <div className="flex flex-col w-40 pl-2">
            <strong>idTx</strong>
            <Input
              placeholder="idTx"
              onChange={(idTx) => setIdTx(idTx)}
              type="number"
            />
          </div>
          <div className="flex flex-col w-40 pl-2">
            <strong>Componente</strong>
            <Input
              placeholder="Componente"
              value={componente}
              onChange={(componente) => setComponente(componente.toUpperCase())}
              type="text"
            />
          </div>
          <div className="flex flex-col w-40 pl-2">
            <strong>idCupo</strong>
            <Input
              placeholder="idCupo"
              value={String(idCupo)}
              onChange={(idCupo) => setIdCupo(Number(idCupo))}
              type="number"
            />
          </div>
          <div className="flex flex-col w-40 pl-2">
            <strong>idAlumnoPrograma</strong>
            <Input
              placeholder="idAlumnoPrograma"
              value={String(idAlumnoPrograma)}
              onChange={(idAlumnoPrograma) =>
                setIdAlumnoPrograma(Number(idAlumnoPrograma))
              }
              type="number"
            />
          </div>
          <Button
            className="ml-3"
            label="Actualizar lista"
            iconName="search"
            onClick={() => getEventos()}
          />
          <Button
            className="ml-3"
            label="Borrar todo"
            iconName="trash"
            onClick={() => setShowDeleteConfirmation(true)}
            theme="red"
          />
          <Button
            className="ml-3"
            label="Exportar a XLS"
            iconName="download"
            onClick={() => exportEventos()}
            theme="green"
          />
        </div>
        <div className="flex flex-col px-10 py-2">
          {eventos.length < 1 && <h2>No se encontraron eventos</h2>}
          <div className="tableListCont">
            <TableList list={eventos} />
          </div>
          <Pagination
            onPageChange={getEventos}
            currentPage={currentPage}
            totalCount={totalEvents}
            pageSize={pageSize}
          />
        </div>
      </div>
    </div>
  );
}

export default Eventos;

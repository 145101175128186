import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardAPI } from "../../../../Api/dashboardAPI";
import { DashboardInitState } from "../../Slices/dashboardSlice";

const services = [
  {
    name: "Backend Notifications",
    getVersion: async () => await dashboardAPI.getVersionBackNotification(),
  },
  {
    name: "Backend Proxy",
    getVersion: async () => await dashboardAPI.getVersionBackProxy(),
  },
  {
    name: "Backend Sync",
    getVersion: async () => await dashboardAPI.getVersionBackSync(null),
  },
  {
    name: "Backend API",
    getVersion: async () => await dashboardAPI.getVersionBackApi(null),
  }
];

export const getServicesVersions = createAsyncThunk(
  "dashboard/getServicesVersions",
  async (_, thunkAPI) => {
    try {
      console.log("getServicesVersions...");
      const listVersions: any[] = [];
      for (const service of services) {
        const { getVersion, name } = service;
        let versionData = {};
        try {
          const res = await getVersion();
          console.log(`${name} res :>> `, res);
          versionData = {
            name,
            version: res.version,
            environment: res.environment,
          };
        } catch (error) {
          versionData = { name, version: "-", environment: "-" };
        }
        listVersions.push(versionData);
      }
      return listVersions;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getServicesVersionsBuilder = (
  builder: ActionReducerMapBuilder<DashboardInitState>
) => {
  builder.addCase(getServicesVersions.fulfilled, (state, action) => {
    console.log("getServicesVersions fulfilled :>> ", action);
    state.servicesVersions = action.payload || [];
  });
};

import React, { useEffect, useState } from "react";
import { dashboardAPI } from "../../Api/dashboardAPI";
import { toastError, toastInfo, toastSuccess } from "../../utils/toast";
import TableList from "../../components/TableList/TableList";
import SlRadio from "@shoelace-style/shoelace/dist/react/radio";
import Button from "../../components/reusable/Button/Button";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import { TransaccionEnTransito } from "../../types/transaccionesEnTransito";
import Pagination from "../../components/reusable/Pagination/Pagination";

function Reservas() {
  const [loading, setLoading] = useState(false);
  const [transactionsEnTransito, setTransaccionEnTransito] = useState<
    TransaccionEnTransito[]
  >([]);
  const [transactionsToProcess, setTransactionsToProcess] = useState<
    TransaccionEnTransito[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const pageLimit = 50;

  const getReservas = async (newPage?: number) => {
    setLoading(true);
    try {
      const { transacciones, totalCount } =
        await dashboardAPI.getTransaccionEnTransito(
          pageLimit,
          newPage ? (newPage - 1) * pageLimit : 0
        );
      setTransaccionEnTransito(transacciones);
      setTotalTransactions(totalCount);
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };

  useEffect(() => {
    getReservas();
  }, []);

  const extractAllIdTx = () => {
    let idList: any[] = [];
    transactionsEnTransito.forEach((t) => {
      const { alumno_carrera_id, id_tx } = t;
      const elemento = { idAlumnoPrograma: alumno_carrera_id, idTx: id_tx };
      if (!idList.includes(elemento)) {
        idList.push(elemento);
      }
    });
    const ordenadas = idList.sort((a, b) => a.id_tx - b.id_tx);
    return ordenadas;
  };

  const extractIdSelectedList = () => {
    let idList: any[] = [];
    transactionsToProcess.forEach((t) => {
      const { alumno_carrera_id, id_tx } = t;
      const elemento = { idAlumnoPrograma: alumno_carrera_id, idTx: id_tx };
      if (!idList.includes(elemento)) {
        idList.push(elemento);
      }
    });
    const ordenadas = idList.sort((a, b) => a.id_tx - b.id_tx);
    return ordenadas;
  };

  const procesarTransaccionesTodasFallidas = async () => {
    setLoading(true);
    try {
      const idList = extractAllIdTx();
      await dashboardAPI.procesarTransaccionesPorLote(idList);
      toastSuccess("El lote fue enviado correctamente a reprocesar");
      setTransactionsToProcess([]);
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };

  const procesarTransaccionesSeleccionadas = async () => {
    setLoading(true);
    try {
      if (!transactionsToProcess.length) {
        toastInfo("Por favor, seleccione algún registro para procesar");
      } else {
        const idList = extractIdSelectedList();
        await dashboardAPI.procesarTransaccionesPorLote(idList);
        toastSuccess("El lote fue enviado correctamente a reprocesar");
        setTransactionsToProcess([]);
      }
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };
  /* -------------------------------------------------------------------------- */
  /*                                   RENDERS                                  */
  /* -------------------------------------------------------------------------- */
  const renderRadioButton = (transaccion: TransaccionEnTransito) => {
    const checked = transactionsToProcess.some(
      (t) =>
        t.id === transaccion.id &&
        t.tipo_de_reserva === transaccion.tipo_de_reserva
    );
    return (
      <div className="flex w-20 justify-center">
        <SlRadio
          className="pl-2"
          value="1"
          checked={checked}
          onClick={() => {
            if (checked) {
              const newList = transactionsToProcess.filter(
                ({ id, tipo_de_reserva }) => {
                  const isRecordToUncheck =
                    id === transaccion.id &&
                    tipo_de_reserva === transaccion.tipo_de_reserva;
                  return !isRecordToUncheck;
                }
              );
              setTransactionsToProcess((t) =>
                t.filter(({ id, tipo_de_reserva }) => {
                  const isRecordToUncheck =
                    id === transaccion.id &&
                    tipo_de_reserva === transaccion.tipo_de_reserva;
                  return !isRecordToUncheck;
                })
              );
            } else {
              setTransactionsToProcess((t) => [...t, transaccion]);
            }
          }}
        />
      </div>
    );
  };

  return (
    <div>
      {loading && <PageLoader />}
      <div className="flex flex-col px-10 py-5">
        <div>
          <div className="flex flex-row items-center my-4 h-8">
            <strong className="text-base">
              Transacciones no procesadas por Oracle
            </strong>
            <Button
              className="mx-3"
              label="Procesar transacciones seleccionadas"
              onClick={procesarTransaccionesSeleccionadas}
            />
            <Button
              className="mx-3"
              label="Procesar TODAS las transacciones fallidas"
              onClick={procesarTransaccionesTodasFallidas}
            />
          </div>
          {transactionsEnTransito.length < 1 && (
            <p>No se encontraron transacciones pendientes a reprocesar</p>
          )}
          <div className="flex flex-row">
            <Button
              className="mx-1 mb-2"
              theme="green"
              label="Seleccionar todo"
              onClick={() => setTransactionsToProcess(transactionsEnTransito)}
            />
            {transactionsToProcess.length > 0 && (
              <Button
                className="mx-1 mb-2"
                theme="red"
                label="Limpiar seleccion"
                onClick={() => setTransactionsToProcess([])}
              />
            )}
          </div>
          <TableList
            customColumns={[
              {
                columnTitle: "Reprocesar",
                boxContent: (d) => renderRadioButton(d),
              },
            ]}
            list={transactionsEnTransito}
          />
          <Pagination
            onPageChange={setCurrentPage}
            currentPage={currentPage}
            totalCount={totalTransactions}
          />
        </div>
      </div>
    </div>
  );
}

export default Reservas;

import { useEffect, useState } from "react";
import Button from "../../../components/reusable/Button/Button";
import TableList from "../../../components/TableList/TableList";
import PageLoader from "../../../components/reusable/PageLoader/PageLoader";
import Input from "../../../components/reusable/Input/Input";
import { useAppDispatch } from "../../../app/store";
import { useSelector } from "react-redux";
import { inicializarAlumno } from "../../../features/Dashboard/AsyncThunks/alumnos/inicializarAlumno";
import {
  getAlumnosInicializados,
  postBorrarIPO,
  postBorrarTodosLosAlumnos,
  postInvalidarOfertas,
  postResetearIPO,
  postSincronizarAlumnos,
  postVencerOfertas,
} from "../../../features/Dashboard/AsyncThunks/alumnos/getAlumnosInicializados";
import { selectAlumnosInicializados } from "../../../features/Dashboard/Slices/alumnosSlice";
import { setLoadingPageScreen } from "../../../features/Dashboard/Slices/dashboardSlice";
import ModalConfirmation from "../../../components/reusable/ModalConfirmation/ModalConfirmation";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlDrawer from "@shoelace-style/shoelace/dist/react/drawer";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { selectGlobalFlags, setDrawerInicializar } from "../../../features/Dashboard/Slices/globalFlagsSlice";

function AlumnosInicializados() {
  const alumnosInicializados = useSelector(selectAlumnosInicializados);
  const { drawerInicializar } = useSelector(selectGlobalFlags);
  const [email, setEmail] = useState("");
  const [identificacion, setIdentificacion] = useState("");
  const [programa, setPrograma] = useState("");
  const [orientacion, setOrientacion] = useState("");
  const dispatch = useAppDispatch();
  const confirmationModalInitialState = {
    show: false,
    title: "",
    dialog: "",
    onConfirm: () => {},
  };
  const [confirmationModal, setConfirmationModal] = useState(
    confirmationModalInitialState
  );
  const clearConfirmationModal = () => {
    setConfirmationModal(confirmationModalInitialState);
  };

  const setLoading = (loaderState: boolean) => {
    dispatch(setLoadingPageScreen(loaderState));
  };

  useEffect(() => {
    ObtenerAlumnosInicializados();
  }, []);

  const handleInicializarAlumno = async () => {
    setLoading(true);
    clearConfirmationModal();
    await dispatch(inicializarAlumno(email));
    setEmail("");
    setLoading(false);
  };

  const ObtenerAlumnosInicializados = async () => {
    setLoading(true);
    try {
      await dispatch(getAlumnosInicializados());
    } catch (error) {
      console.log("error :>> ", error);
    }
    setLoading(false);
  };

  const BorrarTodosLosAlumnos = async () => {
    setLoading(true);
    try {
      await dispatch(postBorrarTodosLosAlumnos());
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  const VencerOfertas = async () => {
    setLoading(true);
    try {
      const i: number | null =
        identificacion && identificacion.length > 0
          ? Number(identificacion)
          : null;
      const p: number | null =
        programa && programa.length > 0 ? Number(programa) : null;
      const o: number | null =
        orientacion && orientacion.length > 0 ? Number(orientacion) : null;
      await dispatch(postVencerOfertas({ i, p, o }));
      setIdentificacion("");
      setOrientacion("");
      setPrograma("");
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  const BorrarIPO = async () => {
    setLoading(true);
    try {
      const i: number | null =
        identificacion && identificacion.length > 0
          ? Number(identificacion)
          : null;
      const p: number | null =
        programa && programa.length > 0 ? Number(programa) : null;
      const o: number | null =
        orientacion && orientacion.length > 0 ? Number(orientacion) : null;
      await dispatch(postBorrarIPO({ i, p, o }));
      setIdentificacion("");
      setOrientacion("");
      setPrograma("");
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  const ResetearIPO = async () => {
    setLoading(true);
    try {
      const i: number | null =
        identificacion && identificacion.length > 0
          ? Number(identificacion)
          : null;
      const p: number | null =
        programa && programa.length > 0 ? Number(programa) : null;
      const o: number | null =
        orientacion && orientacion.length > 0 ? Number(orientacion) : null;
      await dispatch(postResetearIPO({ i, p, o }));
      setIdentificacion("");
      setOrientacion("");
      setPrograma("");
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  const InvalidarOfertas = async () => {
    setLoading(true);
    try {
      const i: number | null =
        identificacion && identificacion.length > 0
          ? Number(identificacion)
          : null;
      const p: number | null =
        programa && programa.length > 0 ? Number(programa) : null;
      const o: number | null =
        orientacion && orientacion.length > 0 ? Number(orientacion) : null;
      await dispatch(postInvalidarOfertas({ i, p, o }));
      setIdentificacion("");
      setOrientacion("");
      setPrograma("");
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  const SincronizarAlumnos = async () => {
    setLoading(true);
    try {
      const i: number | null =
        identificacion && identificacion.length > 0
          ? Number(identificacion)
          : null;
      const p: number | null =
        programa && programa.length > 0 ? Number(programa) : null;
      const o: number | null =
        orientacion && orientacion.length > 0 ? Number(orientacion) : null;
      await dispatch(postSincronizarAlumnos({ i, p, o }));
      setIdentificacion("");
      setOrientacion("");
      setPrograma("");
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  return (
    <div className="flex w-full justify-around px-5 py-5">
      <ModalConfirmation
        show={confirmationModal.show}
        onClose={clearConfirmationModal}
        onConfirm={confirmationModal.onConfirm}
        title={confirmationModal.title}
        message={confirmationModal.dialog}
      />

        <div className="flex flex-grow flex-col items-center">
          <div className="h-auto mb-5 w-auto justify-center mt-2">
            <TableList list={alumnosInicializados} enableSearchbar={false} />
          </div>

          <div className="flex flex-row items-center">
            <Button
              className="self-center h-6"
              onClick={ObtenerAlumnosInicializados}
              label="Actualizar"
            />

            <Button
              className="self-center h-6"
              onClick={() => {
                setConfirmationModal({
                  show: true,
                  onConfirm: BorrarTodosLosAlumnos,
                  title: `¿Desea borrar todos los alumnos?`,
                  dialog: "",
                });
              }}
              label="Borrar Todos"
              theme="red"
            />
          </div>
        </div>

      <SlDrawer
        label="Inicializar alumnos"
        open={drawerInicializar}
        onSlAfterHide={() => dispatch(setDrawerInicializar(false))}
      >
        <div className="">
          <div className="space-y-2">
            <p>
              Ingrese uno o varios mails de alumno separados por , para
              inicializarlos
            </p>
            <Input
              className="w-full"
              placeholder="alumno@mail.utdt, alumno2@mail.utdt"
              onChange={(text) => setEmail(text)}
            />
            <Button
              className="w-full mt-2"
              onClick={() => {
                setConfirmationModal({
                  show: true,
                  onConfirm: handleInicializarAlumno,
                  title: "¿Desea inicializar vía mails?",
                  dialog: "",
                });
              }}
              label="Inicializar"
            />
          </div>

          <div className="mt-10 space-y-2">
            <p>Inicializar por Identificacion, Programa, Orientacion</p>
            <div className="flex items-center justify-center w-full gap-2">
              <Input
                className="ipoField w-14"
                placeholder="I"
                onChange={(text) => setIdentificacion(text)}
              />
              <span>-</span>
              <Input
                className="ipoField w-14"
                placeholder="P"
                onChange={(text) => setPrograma(text)}
              />
              <span>-</span>
              <Input
                className="ipoField w-14"
                placeholder="O"
                onChange={(text) => setOrientacion(text)}
              />
            </div>
            <div className="flex gap-2">
              <SlButton
                outline
                variant="default"
                size="small"
                className="w-1/3"
                onClick={() => {
                  setConfirmationModal({
                    show: true,
                    onConfirm: SincronizarAlumnos,
                    title: `¿Desea inicializar alumnos con el IPO ${identificacion}-${programa}-${orientacion} ?`,
                    dialog: "",
                  });
                }}
              >
                Inicializar
              </SlButton>
              {/* ................................. */}
              <SlButton
                outline
                variant="primary"
                size="small"
                className="w-1/3"
                onClick={() => {
                  setConfirmationModal({
                    show: true,
                    onConfirm: ResetearIPO,
                    title: `¿Desea resetear alumnos con el IPO ${identificacion}-${programa}-${orientacion} ?`,
                    dialog: "",
                  });
                }}
              >
                {/* <SlIcon
                slot="prefix"
                name="gear"
                style={{ fontSize: "20px" }}
              ></SlIcon> */}
                Resetear
              </SlButton>
              {/* ................................. */}
              <SlButton
                outline
                variant="danger"
                size="small"
                className="w-1/3"
                onClick={() => {
                  setConfirmationModal({
                    show: true,
                    onConfirm: BorrarIPO,
                    title: `¿Desea eliminar alumnos con el IPO ${identificacion}-${programa}-${orientacion} ?`,
                    dialog: "",
                  });
                }}
              >
                {/* <SlIcon
                slot="prefix"
                name="gear"
                style={{ fontSize: "20px" }}
              ></SlIcon> */}
                Borrar
              </SlButton>
              {/* ................................. */}
            </div>
            <div className="flex gap-2">
              <SlButton
                outline
                variant="warning"
                size="small"
                className="w-1/2"
                onClick={() => {
                  setConfirmationModal({
                    show: true,
                    onConfirm: InvalidarOfertas,
                    title: `¿Desea invalidar ofertas para el IPO ${identificacion}-${programa}-${orientacion} ?`,
                    dialog: "",
                  });
                }}
              >
                Invalidar ofertas
              </SlButton>
              {/* ................................. */}
              <SlButton
                outline
                variant="danger"
                size="small"
                className="w-1/2"
                onClick={() => {
                  setConfirmationModal({
                    show: true,
                    onConfirm: VencerOfertas,
                    title: `¿Desea vencer ofertas para el IPO ${identificacion}-${programa}-${orientacion} ?`,
                    dialog: "",
                  });
                }}
              >
                Vencer ofertas
              </SlButton>
              {/* ................................. */}
            </div>
          </div>
        </div>
      </SlDrawer>
    </div>
  );
}

export default AlumnosInicializados;

import { useEffect, useState } from "react";
import {
  getProjectInfo,
  getProjectMergeRequests,
  getProjectVersion,
} from "../../services/gitlabApi";
import RepoBranchDetail from "./RepoBranchDetail";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { GitProject, MergeRequest } from "../../services/types/gitlab";
import { FolderRoot } from "lucide-react";

interface Props {
  projectId: number;
}

export default function RepoTimeline({ projectId }: Props) {
  const [info, setInfo] = useState<GitProject |null>(null);
  const [lastVersion, setLastVersion] = useState("");
  const [loading, setLoading] = useState(true);
  const [timelineReady, setTimelineReady] = useState<string[]>([]);
  const [mrs, setMrs] = useState<MergeRequest[]>([]);

  useEffect(() => {
    const fetchers = async () =>{
      const infoRes = await getProjectInfo(projectId);
      const versionRes = await getProjectVersion(projectId, "develop");
      const merges = await getProjectMergeRequests(projectId);
      setInfo(infoRes);
      setLastVersion(versionRes.version);
      setMrs(merges);
    }
    try {
      fetchers();
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  const addReadyBranch = (branch: string) => {
    setTimelineReady((prev: string[]) => [...prev, branch]);
  };

  if (loading || !lastVersion || !info) {
    <p>Consultando repo...</p>;
  }

  console.log(timelineReady);

  return (
    <div className=" min-h-[80px]">
      {/* @ts-ignore */}
      {timelineReady.length < 5 && (
        <SlIcon name="arrow-repeat" className="animate-spin"></SlIcon>
      )}
      <div
        className={`flex items-center ${
          timelineReady.length === 5 ? "" : "hidden"
        }`}
      >
        {info && info?.name && (
          <>
            <div className="rounded-full p-1 mr-2">
              <FolderRoot size={20} color="#f59e0b" />
            </div>
            <h3 className="w-[100px] font-bold text-lg">{info?.name || ""}</h3>
          </>
        )}
        {lastVersion && (
          <div className="flex">
            {["develop", "dev", "testing", "staging", "main"].map((branch) => (
              <RepoBranchDetail
                key={branch}
                projectId={projectId}
                branchName={branch}
                merges={mrs.filter((mr) => mr.target_branch === branch)}
                lastVersion={lastVersion}
                addReadyBranch={addReadyBranch}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
